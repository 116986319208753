<template>
  <!-- 医疗保险-->
  <div class="page">
    <calc-page>
      <template slot="topBox">
        <round-container>
          <year-month-input-field name="出生年月"
            v-model="params.birthday"></year-month-input-field>
          <!-- <div class="item-box">
            <van-cell is-link>
              <div class="row-box">
                <title-item text="出生年月"></title-item>
                <data-item
                  text="请选择出生年月"
                  :minDate="minDate"
                  :maxDate="maxDate"
                  v-model="params.monthTotal"
                ></data-item>
              </div>
            </van-cell>
          </div> -->
          <van-divider />

          <div class="item-box">
            <van-cell is-link>
              <div class="row-box" @click="show = true">
                <title-item text="是否退休"></title-item>
                <span>{{ type }}</span>
              </div>
            </van-cell>
          </div>
          <van-divider />

          <div class="item-box">
            <van-cell>
              <div class="row-box">
                <title-item text="月缴费基数"></title-item>
                <van-field
                  readonly
                  clickable
                  :value="baseFee"
                  @touchstart.native.stop="numberShow = true"
                />
                <span class="unit">元</span>
              </div>
            </van-cell>
          </div>
          <van-divider />
        </round-container>
      </template>

      <template slot="footerBtn">
        <div class="btn-box btn-bk"
          @click="onSubmit"
        >
          开始计算
        </div>
      </template>
    </calc-page>

    <van-action-sheet
      title="是否退休"
      v-model="show"
      :actions="actions"
      @select="onSelect"
    />
    <van-number-keyboard
      v-model="baseFee"
      :show="numberShow"
      :maxlength="6"
      @blur="numberShow = false"
    />
  </div>
</template>

<script>
import {
  Cell,
  CellGroup,
  ActionSheet,
  NumberKeyboard,
  Field,
  Divider,
  Dialog,
} from "vant";
import RoundContainer from "@/views/calc/components/RoundContainer";
import CalcPage from "@/components/CalcPage";
import TitleItem from "@/views/calc/TitleItem";
// import DataItem from "@/views/calc/DataItem";
import to from "@/utils/to";
import { getMedicalInsurance } from "@/api/tool";
import YearMonthInputField from "@/views/calc/components/YearMonthInputField";

export default {
  components: {
    CalcPage,
    TitleItem,
    // DataItem,
    RoundContainer,
    [Field.name]: Field,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [ActionSheet.name]: ActionSheet,
    [NumberKeyboard.name]: NumberKeyboard,
    [Divider.name]: Divider,
    YearMonthInputField,
  },
  data() {
    return {
      baseFee: "",
      params: {
        isRetire: true,
        birthday: "",
        baseFee: "",
      },

      show: false,
      numberShow: false,
      actions: [{ name: "已退休" }, { name: "未退休" }],
      // minDate: new Date(),
      // maxDate: new Date(),
    };
  },
  computed: {
    type() {
      return this.params.isRetire ? '已退休' : '未退休'
    } 
  },
  methods: {
    onSelect(item) {
      if (item.name == "已退休") {
        this.params.isRetire = true;
      } else {
        this.params.isRetire = false;
      }
      this.show = false;
    },
    async onSubmit() {
      const { birthday, isRetire } = this.params
      if (birthday === "") {
        this.$toast("请填写出生年月");
        return;
      }

      const curYear = new Date().getFullYear();
      const itemMonthTotalYear = birthday.substring(0,4);
      if ((curYear - itemMonthTotalYear) < 18) {
        Dialog.alert({
          title: "年龄不正确",
          message: "年龄需满18岁，请重新输入出生年月"
        });
        return;
      }

      if (this.baseFee === "") {
        this.$toast.fail("请输入月缴费基数");
        return;
      }

      this.$showLoading("加载中...");

      const params = {
        baseFee: parseInt(this.baseFee),
        birthday: birthday + '/01',
        isRetire
      }
      let [err, res] = await to(getMedicalInsurance(params));
      this.$hideLoading();
      if (err) {
        this.$toast.fail("获取失败");
        return;
      }
      this.$router.push({ name: "MedicResultPage", query: { res: JSON.stringify(res) } });

      // this.$nextTick(()=>{
      //   scroll(0,document.body.scrollHeight)
      // });
    },
  },
};
</script>

<style scoped>
.page >>> .van-divider {
  margin: 0;
}

.item-box {
  padding: 24px 0 12px 0;
}

.item-box >>> .van-cell {
  padding: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.row-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row-box >>> .TitleItem__title--2DuKR {
  width: 150px;
}

.row-box >>> .van-field__control {
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.row-box span {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.row-box .unit {
  font-size: 14px;
  font-weight: normal;
  position: relative;
  top: 1px;
}

.row-box >>> .van-cell::after {
  border: none;
}

.btn-box {
  position: fixed;
  bottom: 10px;
  background: #cccccc;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  padding: 10px 130px;
}

.btn-bk {
  background:#658CF1;
}
</style>