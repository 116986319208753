<template>
  <div class="field-box">
    <div class="field">
      <div class="name" :style="{ color: color }">
        <span>{{ name }}</span>
        <img
          v-if="tip"
          src="@/assets/imgs/calc/link.png"
          class="tip"
          @click="$emit('tip')"
        />
      </div>
      <div class="data">
        <slot></slot>
      </div>
    </div>
    <van-divider />
  </div>
</template>

<script>
import { Divider } from "vant";

export default {
  props: {
    name: {
      type: String,
    },
    tip: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    [Divider.name]: Divider,
  },
  computed: {
    color() {
      return this.disabled ? '#E6E6E6' : '#808080'
    }
  },
};
</script>

<style scoped>
.field-box >>> .van-divider {
  margin: 0;
}

.field {
  display: flex;
  align-items: center;
  height: 60px;
}

.name {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  /* color: #808080; */
  display: flex;
  align-items: center;
}

.tip {
  width: 14px;
  height: 14px;
}

.data {
  flex: 1;
  text-align: right;
  font-size: 24px;
  font-weight: bold;
  color: #1a1a1a;
}
</style>