import { render, staticRenderFns } from "./RoundContainer.vue?vue&type=template&id=085c2ab4&scoped=true&"
import script from "./RoundContainer.vue?vue&type=script&lang=js&"
export * from "./RoundContainer.vue?vue&type=script&lang=js&"
import style0 from "./RoundContainer.vue?vue&type=style&index=0&id=085c2ab4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "085c2ab4",
  null
  
)

export default component.exports