<template>
  <div class="round">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.round {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 8px;
}
</style>